import { FormattedMessage } from "react-intl";

interface RealmLeadBannerProps {
    backgroundImage: string;
    logoImage: string;
    institution: string;
    textClass?: string;
    text: string;
    link: string;
}

export default function RealmLeadBanner({
    backgroundImage,
    logoImage,
    institution,
    textClass,
    text,
    link,
}: RealmLeadBannerProps) {
    return (
        <div>
            <div className="relative h-28 sm:h-40 w-full">
                <img
                    src={backgroundImage}
                    className="w-full object-cover absolute h-28 sm:h-40"
                    alt=""
                />
                <div className="h-full text-white relative z-10 max-w-7xl mx-auto px-6 py-8 font-light flex items-center">
                    <img
                        src={logoImage}
                        className="h-16 sm:h-24"
                        alt={`${institution} Logo`}
                    />
                </div>
            </div>
            <div
                className={`w-full text-sm sm:text-base text-white ${textClass}`}
            >
                <p className="max-w-7xl mx-auto px-6 py-1">
                    {text}
                    <a
                        href={link}
                        className="underline ml-2"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FormattedMessage id="Learn more" />
                    </a>
                    .
                </p>
            </div>
        </div>
    );
}
