import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Link, router } from "@inertiajs/react";
import { FormattedMessage } from "react-intl";

export default function EmailVerifiedModal() {
    return (
        <Dialog
            open
            onClose={() => router.visit(route("home"))}
            className="relative z-[999]"
        >
            <div className="fixed inset-0 bg-gray-500/75" aria-hidden="true" />
            <div className="fixed inset-0 flex w-screen items-center justify-center p-8">
                <DialogPanel className="w-full sm:max-w-lg rounded bg-white p-8">
                    <div>
                        <div className="flex items-center">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center size-12 rounded-full bg-green-100 border-green-100 border-4 sm:mx-0 sm:size-10">
                                <CheckCircleIcon className="size-6 text-green-600" />
                            </div>
                            <DialogTitle className="text-2xl leading-6 text-gray-900 font-light ml-4">
                                <FormattedMessage id="Your email is" />{" "}
                                <span className="font-bold">
                                    <FormattedMessage id="verified" />
                                </span>
                            </DialogTitle>
                        </div>
                        <div className="mt-8 text-left">
                            <div className="mt-8">
                                <p className="text-base text-gray-500">
                                    <FormattedMessage id="Thanks for verifying your email, return to your" />{" "}
                                    <Link
                                        href={route("home")}
                                        className="text-blue-500 hover:text-blue-900"
                                    >
                                        <FormattedMessage id="home page" />
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
}
